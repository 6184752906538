import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';

import { useMetadata } from '~/providers/Metadata';
import { useNavbar } from '~/providers/Navbar';
import { usePlan } from '~/providers/PlanProvider';
import { useProject } from '~/providers/Project';
import { useUserSettings } from '~/providers/UserSettings';

import { usePoolingAsync } from '~/hooks/pooling';

import { identifyUser } from '~/utils/sentry';

import { getRouter } from './routes';

export const Routes = () => {
  const { startPooling } = usePoolingAsync();
  const { updateNavbarItems, clearNavbarItems, removeNavbarItem } = useNavbar();
  const { getProject } = useProject();
  const { t } = useTranslation();
  const { user } = useAuthenticator();
  const { updateScreenTitle } = useMetadata();
  const { getCredits } = usePlan();
  const { getSettings } = useUserSettings();

  const loaderProps = useMemo(
    () => ({
      updateNavbarItems,
      clearNavbarItems,
      removeNavbarItem,
      startPooling,
      getProject,
      updateScreenTitle,
      getCredits,
      getSettings,
      t,
    }),
    [
      updateNavbarItems,
      clearNavbarItems,
      removeNavbarItem,
      startPooling,
      getProject,
      updateScreenTitle,
      getCredits,
      getSettings,
      t,
    ],
  );

  const router = useMemo(() => getRouter(loaderProps), [loaderProps]);

  useEffect(() => {
    identifyUser(user.userId, user.username);
  }, [user.userId, user.username]);

  return <RouterProvider router={router} />;
};
