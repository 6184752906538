import { LocaleType } from '~/i18n';

export const getDefaultSettings = (
  language: LocaleType,
): { language: LocaleType; currency: string } => {
  if (language === 'pt-BR') {
    return {
      language: 'pt-BR',
      currency: 'BRL',
    };
  }

  return {
    language: 'en-US',
    currency: 'USD',
  };
};
