import { message } from 'antd';
import { MessageType } from 'antd/es/message/interface';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Errors } from '~/constants/errors';

export type MessageContextType = {
  contextHolder: ReactNode;
  showErrorMessage: (error: Errors, data?: any) => MessageType;
};

const MessageContext = createContext({} as MessageContextType);

const MessageProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const showErrorMessage = useCallback(
    (error: Errors, data?: any) =>
      messageApi.open({
        content: t(`errors.${error}`, data) as string,
        type: 'error',
      }),
    [messageApi, t],
  );

  const value = useMemo(
    () => ({ contextHolder, showErrorMessage }),
    [contextHolder, showErrorMessage],
  );

  return (
    <MessageContext.Provider value={value}>{children}</MessageContext.Provider>
  );
};

const useMessage = (): MessageContextType => {
  const context = useContext(MessageContext);

  if (!context) {
    throw new Error('useMessage must be wrapped within a MessageContextType');
  }

  return context;
};

export { MessageProvider, useMessage };
