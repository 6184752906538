import { FaGlobeAmericas, FaPoundSign } from 'react-icons/fa';
import { FaBrazilianRealSign, FaDollarSign, FaEuroSign } from 'react-icons/fa6';

import { LocaleType } from '~/i18n';
import { SettingsOptionType } from '~/types/settings';

export const CURRENCIES: SettingsOptionType[] = [
  {
    label: 'USD',
    value: 'USD',
    icon: FaDollarSign,
  },
  {
    label: 'BRL',
    value: 'BRL',
    icon: FaBrazilianRealSign,
  },
  {
    label: 'CAD',
    value: 'CAD',
    icon: FaDollarSign,
  },
  {
    label: 'EUR',
    value: 'EUR',
    icon: FaEuroSign,
  },
  {
    label: 'GBP',
    value: 'GBP',
    icon: FaPoundSign,
  },
];

export const LANGUAGES: (SettingsOptionType & { value: LocaleType })[] = [
  {
    label: 'English',
    value: 'en-US',
    icon: FaGlobeAmericas,
  },
  {
    label: 'Português',
    value: 'pt-BR',
    icon: FaGlobeAmericas,
  },
];
