/* eslint-disable import/default */
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React from 'react';
import ReactDOM from 'react-dom/client';

import '~/config/amplify';
import '~/i18n/index';
import './index.css';

import { sentryConfig } from '~/config/sentry';

import { App } from './App';

dayjs.extend(isBetween);

sentryConfig();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
