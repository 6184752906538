import { Amplify } from 'aws-amplify';

const amplifyConfig = {
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_USER_POOL_ID ?? '',
      userPoolClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID ?? '',
      loginWith: {
        email: true,
      },
    },
  },
};

Amplify.configure(amplifyConfig, {
  ssr: true,
});
