import { css, Theme } from '@emotion/react';

export const GlobalStyles = (theme: Theme) => css`
  [data-amplify-authenticator] {
    --amplify-components-button-link-color: ${theme.colorPrimary};
    --amplify-components-button-link-hover-color: ${theme.colorPrimaryHover};
    --amplify-components-button-link-focus-background-color: ${theme.colorPrimaryBg};
    --amplify-components-button-link-focus-color: ${theme.colorPrimaryActive};
    --amplify-components-button-link-focus-border-color: ${theme.colorPrimaryActive};
    --amplify-components-button-link-active-color: ${theme.colorPrimaryTextActive};
    --amplify-components-button-link-active-border-color: ${theme.colorPrimaryTextActive};
    --amplify-components-authenticator-modal-background-color: ${theme.colorBgContainer};
    --amplify-components-authenticator-router-border-color: ${theme.colorFillSecondary};
    --amplify-components-authenticator-router-background-color: ${theme.colorBgElevated};
    --amplify-components-field-label-color: ${theme.colorWhite};
    --amplify-components-tabs-item-active-color: ${theme.colorPrimaryActive};
    --amplify-components-tabs-item-active-border-color: ${theme.colorPrimaryActive};
    --amplify-components-tabs-item-color: ${theme.colorPrimaryActive};
    --amplify-components-tabs-item-border-color: ${theme.colorPrimaryActive};
    --amplify-components-tabs-item-hover-color: ${theme.colorWhite};
    --amplify-components-text-error-color: ${theme.colorError};
    --amplify-components-heading-color: ${theme.colorWhite};
    --amplify-components-text-color: ${theme.colorWhite};
    --amplify-components-button-border-color: transparent;
    --amplify-components-button-color: ${theme.colorPrimary};
    --amplify-components-button-primary-hover-color: ${theme.colorWhite};
    --amplify-components-button-primary-hover-background-color: ${theme.colorPrimaryHover};
    --amplify-components-button-primary-active-background-color: ${theme.colorPrimaryActive};
    --amplify-components-button-primary-focus-background-color: ${theme.colorPrimaryActive};
    --amplify-components-button-primary-background-color: ${theme.colorPrimary};
    --amplify-components-fieldcontrol-focus-border-color: ${theme.colorPrimaryActive};
    --amplify-components-button-hover-color: ${theme.colorPrimaryBgHover};
    --amplify-components-button-hover-border-color: transparent;
    --amplify-components-button-focus-color: ${theme.colorPrimaryActive};
    --amplify-components-button-focus-border-color: transparent;
    --amplify-components-button-active-color: ${theme.colorPrimaryBg};
    --amplify-components-button-active-border-color: transparent;

    --amplify-radii-small: 0;
    --amplify-radii-medium: 0;
    --amplify-radii-large: 0;
    --amplify-space-small: 1rem;
    --amplify-space-medium: 1.5rem;
    --amplify-space-large: 2rem;
    --amplify-border-widths-small: 2px;
    --amplify-border-widths-medium: 4px;
    --amplify-border-widths-large: 8px;
  }

  [data-amplify-authenticator] [data-amplify-router] {
    border-radius: ${theme.borderRadiusLG}px;
  }

  .amplify-tabs__list {
    display: none;
    border-radius: ${theme.borderRadiusLG}px;
  }

  .amplify-input,
  .amplify-field__show-password {
    background-color: ${theme.colorFillSecondary};
    border-color: ${theme.colorPrimary};
    color: ${theme.colorWhite};
    border-radius: ${theme.borderRadiusLG}px;

    &:focus,
    &:active {
      border-color: ${theme.colorPrimaryActive};
    }
  }

  .amplify-button--primary {
    border-radius: ${theme.borderRadiusLG}px;
  }

  .amplify-button:focus {
    box-shadow: none;
  }

  #signIn-tab {
    border-top-left-radius: ${theme.borderRadiusLG}px;
  }

  #signUp-tab {
    border-top-right-radius: ${theme.borderRadiusLG}px;
  }

  *::-webkit-scrollbar {
    width: ${theme.sizeXS}px;
    height: ${theme.sizeXS}px;
  }

  *::-webkit-scrollbar-track {
    background-color: ${theme.colorBgElevated};
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${theme.colorFillSecondary};
    border-radius: 14px;
  }
`;
