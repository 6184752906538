import { css, Theme } from '@emotion/react';

export const Header = (theme: Theme) => css`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 20;
  padding: 0 16px;
  background-color: ${theme.geekblue2};
  border-bottom: 1px solid ${theme.colorBorderSecondary};
`;

export const Dropdown = css`
  cursor: pointer;
`;

export const DropdownContent = (theme: Theme) => css`
  background-color: ${theme.colorBgElevated};
  border-radius: ${theme.borderRadiusLG}px;
  box-shadow: ${theme.boxShadowSecondary};
`;

export const DropdownMenu = css`
  box-shadow: none;
`;

export const DropdownHeader = (theme: Theme) => css`
  padding: ${theme.paddingXS}px ${theme.padding}px;
`;

export const DropdownDivider = css`
  margin: 0;
`;

export const LanguageIcon = (theme: Theme) => css`
  font-size: 24px;
  color: ${theme.colorTextSecondary};
`;

export const Logo = css`
  width: 2.25rem;
  height: 2.25rem;
`;

export const Content = (theme: Theme) => css`
  background-color: ${theme.colorBgContainer};
  overflow: auto;
`;

export const ErrorContainer = (theme: Theme) => css`
  background-color: ${theme.colorBgContainer};
  width: 100vw;
  height: 100vh;
`;
