import { Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';

import * as styles from './styles';

export const Error = () => {
  const { t } = useTranslation();
  const error: any = useRouteError();
  console.error(error);

  return (
    <Flex vertical css={styles.ErrorContainer} align="center" justify="center">
      <Typography.Title level={4}>{t('errors.oops')}</Typography.Title>
      <Typography.Text>{t('errors.genericError')}</Typography.Text>
      <Typography.Text type="secondary" italic>
        {error.statusText || error.message}
      </Typography.Text>
    </Flex>
  );
};
