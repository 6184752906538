import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

export const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

api.interceptors.request.use(async config => {
  const session = await fetchAuthSession();

  config.headers.Authorization = session.tokens?.idToken?.toString();

  return config;
});
