import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import { NAVBAR_DEFAULT_ITEMS } from '~/constants/navbar';

export type NavbarItemType = {
  title: string;
  to: string;
  enabledOnChildren?: boolean;
};

export type NavbarDefaultItem = (typeof NAVBAR_DEFAULT_ITEMS)[number];

export type NavbarItemsType = { [key in NavbarDefaultItem]: NavbarItemType[] };

export type UpdateNavbarItemsFunc = (
  parent: NavbarDefaultItem,
  items: NavbarItemType[],
) => void;

export type RemoveNavbarItemFunc = (
  parent: NavbarDefaultItem,
  path: string,
) => void;

export type ClearNavbarItemsFunc = (parent: NavbarDefaultItem) => void;

export type UpdateCurrentParentFunc = (parent?: NavbarDefaultItem) => void;

export type NavbarContextType = {
  navbarItems: NavbarItemsType;
  updateNavbarItems: UpdateNavbarItemsFunc;
  clearNavbarItems: ClearNavbarItemsFunc;
  clearAllNavbarItems: () => void;
  removeNavbarItem: RemoveNavbarItemFunc;
  updateCurrentParent: UpdateCurrentParentFunc;
};

const NavbarContext = createContext({} as NavbarContextType);

const NavbarProvider = ({ children }: { children: ReactNode }) => {
  const [navbarItems, setNavbarItems] = useState<NavbarItemsType>({
    dashboard: [],
    projects: [],
  });

  const currentParentRef = useRef<NavbarDefaultItem>();

  const updateCurrentParent: UpdateCurrentParentFunc = useCallback(parent => {
    currentParentRef.current = parent;
  }, []);

  const clearNavbarItems: ClearNavbarItemsFunc = useCallback(parent => {
    setNavbarItems(prevItems => ({
      ...prevItems,
      [parent]: [],
    }));
  }, []);

  const updateNavbarItems: UpdateNavbarItemsFunc = useCallback(
    (parent, items) => {
      if (currentParentRef.current && currentParentRef.current !== parent) {
        clearNavbarItems(parent);
        return;
      }
      setNavbarItems(prevItems => ({
        ...prevItems,
        [parent]: [...items],
      }));
    },
    [clearNavbarItems],
  );

  const clearAllNavbarItems = useCallback(() => {
    setNavbarItems({
      dashboard: [],
      projects: [],
    });
  }, []);

  const removeNavbarItem: RemoveNavbarItemFunc = useCallback((parent, path) => {
    setNavbarItems(prevItems => ({
      ...prevItems,
      [parent]: prevItems[parent].filter(item => item.to !== path),
    }));
  }, []);

  const value = useMemo(
    () => ({
      clearNavbarItems,
      clearAllNavbarItems,
      navbarItems,
      updateNavbarItems,
      removeNavbarItem,
      updateCurrentParent,
    }),
    [
      clearNavbarItems,
      clearAllNavbarItems,
      navbarItems,
      updateNavbarItems,
      removeNavbarItem,
      updateCurrentParent,
    ],
  );

  return (
    <NavbarContext.Provider value={value}>{children}</NavbarContext.Provider>
  );
};

const useNavbar = (): NavbarContextType => {
  const context = useContext(NavbarContext);

  if (!context) {
    throw new Error('useNavbar must be wrapped within a NavbarContextType');
  }

  return context;
};

export { NavbarProvider, useNavbar };
