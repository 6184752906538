import * as Sentry from '@sentry/react';

export const addBreadcrumb = (
  data: Sentry.Breadcrumb['data'],
  description: string,
) => {
  Sentry.addBreadcrumb({
    type: 'error',
    category: 'error',
    level: 'error',
    message: description,
    timestamp: Date.now(),
    data,
  });
};

export const logError = (
  description: string,
  data?: Sentry.Breadcrumb['data'],
) => {
  if (data) {
    addBreadcrumb(data, description);
  }

  Sentry.captureException(new Error(description));
};

export const logException = (
  error: Error,
  data?: Sentry.Breadcrumb['data'],
) => {
  const description = error.message ?? error.name ?? 'unknown error';

  if (data) {
    addBreadcrumb(data, description);
  }

  Sentry.captureException(new Error(error.message ?? error.name));
};

export const identifyUser = (id: string, email: string) => {
  Sentry.setUser({ id, email });
};

export const clearUser = () => {
  Sentry.setUser(null);
};
