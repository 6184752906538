import { css, Theme } from '@emotion/react';

export const Container = (theme: Theme) => css`
  background-color: ${theme.colorBgContainer};
  white-space: pre-line;
  width: 100vw;
  height: 100vh;
`;

export const Message = css`
  text-align: center;
`;
