import { redirect } from 'react-router-dom';

import { Error as RootError } from '~/screens/Root/error';

import { sentryCreateBrowserRouter } from '~/config/sentry';
import { GetRouterFunctionType } from '~/types/routeFunctions';
import { dynamicImportScreen } from '~/utils/dynamic-import';

// TODO: Extract this to a multiple files
// TODO: Add react helmet title to all pages
export const getRouter: GetRouterFunctionType = loaderProps =>
  sentryCreateBrowserRouter([
    {
      path: '/',
      errorElement: <RootError />,
      ...dynamicImportScreen('Root'),
      children: [
        {
          index: true,
          loader: () => redirect('/projects'),
        },
        {
          path: 'projects',
          children: [
            {
              index: true,
              ...dynamicImportScreen('Projects', { loaderProps }),
            },
            {
              path: ':projectId',
              children: [
                {
                  index: true,
                  ...dynamicImportScreen('Project', { loaderProps }),
                },
                {
                  path: 'briefing',
                  ...dynamicImportScreen('ProjectForm', { loaderProps }),
                },
                {
                  path: 'campaign',
                  children: [
                    {
                      index: true,
                      loader: ({
                        params,
                      }: {
                        params: { [key: string]: string };
                      }) => redirect(`/projects/${params.projectId}`),
                    },
                    {
                      path: ':campaignId',
                      children: [
                        {
                          index: true,
                          ...dynamicImportScreen('Campaign', {
                            loaderProps,
                          }),
                        },
                        {
                          path: 'briefing',
                          ...dynamicImportScreen('CampaignForm', {
                            loaderProps,
                          }),
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'create-campaign',
                  ...dynamicImportScreen('CampaignForm', { loaderProps }),
                },
              ],
            },
            {
              path: 'create-project',
              ...dynamicImportScreen('ProjectForm', { loaderProps }),
            },
          ],
        },
      ],
    },
  ]);
