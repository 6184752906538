import { Flex, Typography } from 'antd';

import * as styles from './styles';

type FullScreenMessageProps = {
  message: string;
};

export const FullScreenMessage = ({ message }: FullScreenMessageProps) => (
  <Flex align="center" justify="center" css={styles.Container}>
    <Typography.Title css={styles.Message} level={4}>
      {message}
    </Typography.Title>
  </Flex>
);
