import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export type UpdateScreenTitleFunc = (newTitle: string) => void;

export type MetadataContextType = {
  updateScreenTitle: UpdateScreenTitleFunc;
};

const MetadataContext = createContext({} as MetadataContextType);

const MetadataProvider = ({ children }: { children: ReactNode }) => {
  const [title, setTitle] = useState('');

  const { t, i18n } = useTranslation();

  const updateScreenTitle: UpdateScreenTitleFunc = useCallback(
    newTitle => setTitle(newTitle),
    [],
  );

  const value = useMemo(() => ({ updateScreenTitle }), [updateScreenTitle]);

  return (
    <MetadataContext.Provider value={value}>
      <Helmet>
        <html lang={i18n.language} />
        <meta name="description" content={t('header.description')} />
        {title && <title>markettools.ai | {title}</title>}
      </Helmet>
      {children}
    </MetadataContext.Provider>
  );
};

const useMetadata = (): MetadataContextType => {
  const context = useContext(MetadataContext);

  if (!context) {
    throw new Error('useMetadata must be wrapped within a MetadataContextType');
  }

  return context;
};

export { MetadataProvider, useMetadata };
