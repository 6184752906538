import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';

import { api } from '~/config/api';
import { ProjectType } from '~/types/project';

export type GetProjectFunc = (
  id: string,
  forceRefresh?: boolean,
) => Promise<ProjectType>;

export type ProjectContextType = {
  getProject: GetProjectFunc;
};

const ProjectContext = createContext({} as ProjectContextType);

const ProjectProvider = ({ children }: { children: ReactNode }) => {
  const loadedProjects = useRef<{
    [key: string]: ProjectType;
  }>({});

  const getProject: GetProjectFunc = useCallback(async (id, forceRefresh) => {
    if (!loadedProjects.current[id] || forceRefresh) {
      const response = await api.get<ProjectType>(`/company/${id}`);

      loadedProjects.current[id] = response.data;
      return { ...response.data };
    }

    return loadedProjects.current[id];
  }, []);
  const value = useMemo(() => ({ getProject }), [getProject]);

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};

const useProject = (): ProjectContextType => {
  const context = useContext(ProjectContext);

  if (!context) {
    throw new Error('useProject must be wrapped within a ProjectContextType');
  }

  return context;
};

export { ProjectProvider, useProject };
