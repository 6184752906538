import {
  DynamicLoaderFunctionType,
  DynamicRouteFunctionType,
} from '~/types/routeFunctions';

export const dynamicImportLoader: DynamicLoaderFunctionType =
  (name, props) => async params => {
    const { loader } = await import(`~/screens/${name}/loader.ts`);
    return loader(props)(params);
  };

export const dynamicImportScreenComponent = (name: string) => async () => {
  const screen = await import(`~/screens/${name}/index.tsx`);

  return {
    Component: screen.default,
  };
};

export const dynamicImportScreen: DynamicRouteFunctionType = (
  name,
  options,
) => {
  return {
    ...(options?.loaderProps
      ? {
          loader: dynamicImportLoader(name, options.loaderProps),
        }
      : {}),
    lazy: dynamicImportScreenComponent(name),
  };
};
