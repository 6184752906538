import enUS from 'antd/locale/en_US';
import ptBR from 'antd/locale/pt_BR';
import dayjs from 'dayjs';

import 'dayjs/locale/pt-br';
import 'dayjs/locale/en';

import { LocaleType } from '~/i18n';

export const getLocale = (language: LocaleType) => {
  if (language === 'en-US') {
    dayjs.locale('en');
    return enUS;
  }

  dayjs.locale('pt-br');
  return ptBR;
};
