import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { api } from '~/config/api';
import { WalletResponse } from '~/types/plan';
import { logError } from '~/utils/sentry';

export type PlanContextType = {
  credits?: number;
  getCredits: () => Promise<void>;
};

const PlanContext = createContext({} as PlanContextType);

const PlanProvider = ({ children }: { children: ReactNode }) => {
  const [credits, setCredits] = useState<number>();

  const getCredits = useCallback(async () => {
    try {
      const response = await api.get<WalletResponse>('/wallet');
      setCredits(response.data.balance);
    } catch (e) {
      logError('Failed to get credits', e as Error);
    }
  }, []);

  useEffect(() => {
    getCredits();
  }, [getCredits]);

  const value = useMemo(() => ({ credits, getCredits }), [credits, getCredits]);

  return <PlanContext.Provider value={value}>{children}</PlanContext.Provider>;
};

const usePlan = (): PlanContextType => {
  const context = useContext(PlanContext);

  if (!context) {
    throw new Error('usePlan must be wrapped within a PlanContextType');
  }

  return context;
};

export { PlanProvider, usePlan };
