import i18n from 'i18next';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { SettingsModal } from '~/components/SettingsModal';
import { ToSModal } from '~/components/ToSModal';

import { api } from '~/config/api';
import { logError } from '~/utils/sentry';

type SettingsOptionsType = {
  updateLng?: boolean;
};

export type SettingsType = {
  language?: string;
  currency?: string;
  signedToSAt?: string;
};

export type UserSettingsContextType = {
  settings: SettingsType;
  getSettings: () => SettingsType;
};

const UserSettingsContext = createContext({} as UserSettingsContextType);

const UserSettingsProvider = ({ children }: { children: ReactNode }) => {
  const [settings, setSettings] = useState({} as SettingsType);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showToSModal, setShowTOSModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const settingsRef = useRef(settings);

  const loadSettings = useCallback(async (options?: SettingsOptionsType) => {
    try {
      const {
        data: { data },
      } = await api.get<{ data: SettingsType }>('/entries');

      if (!data.language || !data.currency) {
        setShowSettingsModal(true);
        return;
      }

      setShowSettingsModal(false);
      setSettings({
        language: data.language,
        currency: data.currency,
      });

      if (options?.updateLng) {
        // i18n.changeLanguage(data.language);
        i18n.changeLanguage('en-US');
      }

      if (!data.signedToSAt) {
        setShowTOSModal(true);
        return;
      }

      setShowTOSModal(false);
      setSettings(prev => ({ ...prev, signedToSAt: data.signedToSAt }));
    } catch (e) {
      console.error(e);
      logError('Failed to load settings', e as Error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateSettings = useCallback(
    async (data: SettingsType) => {
      setIsLoading(true);
      try {
        await api.patch('/entries', {
          language: data.language,
          currency: data.currency,
        });
        loadSettings({
          updateLng: i18n.language !== data.language,
        });
      } catch (e) {
        logError('Failed to update settings', e as Error);
        setIsLoading(false);
      }
    },
    [loadSettings],
  );

  const acceptToS = useCallback(async () => {
    setIsLoading(true);
    try {
      await api.patch('/entries', {
        signedToSAt: new Date().toISOString(),
      });
      loadSettings();
    } catch (e) {
      logError('Failed to accept ToS', e as Error);
    } finally {
      setIsLoading(false);
    }
  }, [loadSettings]);

  const getSettings = useCallback(() => settingsRef.current, []);

  useEffect(() => {
    loadSettings();
  }, [loadSettings]);

  useEffect(() => {
    settingsRef.current = settings;
  }, [settings]);

  const value = useMemo(
    () => ({
      settings,
      getSettings,
    }),
    [getSettings, settings],
  );

  return (
    <UserSettingsContext.Provider value={value}>
      {children}
      <SettingsModal
        show={showSettingsModal}
        onContinue={updateSettings}
        isLoading={isLoading}
      />
      <ToSModal
        show={showToSModal}
        onContinue={acceptToS}
        isLoading={isLoading}
      />
    </UserSettingsContext.Provider>
  );
};

const useUserSettings = (): UserSettingsContextType => {
  const context = useContext(UserSettingsContext);

  if (!context) {
    throw new Error(
      'useUserSettings must be wrapped within a UserSettingsContextType',
    );
  }

  return context;
};

export { UserSettingsProvider, useUserSettings };
