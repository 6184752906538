import { Modal, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa6';

type ToSModalProps = {
  show: boolean;
  onContinue: () => void;
  isLoading: boolean;
};

export const ToSModal = ({ show, onContinue, isLoading }: ToSModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('settings.tosTitle')}
      centered
      closable={false}
      maskClosable={false}
      cancelButtonProps={{
        hidden: true,
      }}
      okText={t('settings.accept')}
      okButtonProps={{
        icon: <FaCheck />,
        iconPosition: 'end',
      }}
      onOk={onContinue}
      confirmLoading={isLoading}
      open={show}>
      <Typography.Text type="secondary">
        <Trans
          i18nKey="settings.tosText"
          components={{
            1: <Typography.Link target="_blank" href="/" />,
          }}
        />
      </Typography.Text>
    </Modal>
  );
};
