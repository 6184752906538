import { Authenticator, AuthenticatorProps } from '@aws-amplify/ui-react';
import { signUp, SignUpInput } from 'aws-amplify/auth';
import { I18n } from 'aws-amplify/utils';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Footer, Header } from '~/components/Auth';

import enUSTranslation from '~/i18n/locales/en-US/auth.json';
import ptBRTranslation from '~/i18n/locales/pt-BR/auth.json';

type AuthProviderProps = {
  children: React.ReactNode;
};

const getComponents = () => ({
  LoginFooter: () => <Footer />,
  LoginAndSignUpHeader: () => <Header />,
  SignUpFooter: () => <Footer isSignUp />,
  ForgotPasswordHeader: () => <Header forgotPassword />,
});

I18n.putVocabularies({
  ptBR: ptBRTranslation,
  enUS: enUSTranslation,
});

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [inviteCode, setInviteCode] = useState<string>();

  const { t, i18n } = useTranslation();

  const components: AuthenticatorProps['components'] = useMemo(() => {
    const authComponents = getComponents();

    return {
      SignIn: {
        Header: authComponents.LoginAndSignUpHeader,
        Footer: authComponents.LoginFooter,
      },
      SignUp: {
        Header: authComponents.LoginAndSignUpHeader,
        Footer: authComponents.SignUpFooter,
      },
      ForgotPassword: {
        Header: authComponents.ForgotPasswordHeader,
      },
    };
  }, []);

  const formFields: AuthenticatorProps['formFields'] = useMemo(() => {
    if (inviteCode) {
      return {
        signUp: {
          invite: {
            placeholder: t('form.yourInvite'),
            isRequired: false,
            label: t('form.invite'),
            isReadOnly: true,
            value: inviteCode,
          },
        },
      };
    }

    return {};
  }, [inviteCode, t]);

  const services: AuthenticatorProps['services'] = useMemo(
    () => ({
      async handleSignUp(input: SignUpInput) {
        if (inviteCode) {
          return signUp({
            ...input,
            options: {
              ...input.options,
              userAttributes: {
                ...input.options?.userAttributes,
                'custom:claims': JSON.stringify({ invite: inviteCode }),
              },
            },
          });
        }

        return signUp(input);
      },
    }),
    [inviteCode],
  );

  useEffect(() => {
    I18n.setLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const invite = queryParams.get('invite');

    if (invite) {
      setInviteCode(invite);
    }
  }, []);

  return (
    <Authenticator
      variation="modal"
      components={components}
      loginMechanisms={['email']}
      i18nIsDynamicList
      formFields={formFields}
      initialState={inviteCode ? 'signUp' : 'signIn'}
      services={services}
      key={i18n.language + inviteCode}>
      {children}
    </Authenticator>
  );
};
