import { useAuthenticator } from '@aws-amplify/ui-react';
import { Flex, Typography } from 'antd';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from '~/assets/logo.svg';

import * as styles from './styles';

type HeaderProps = {
  forgotPassword?: boolean;
};

export const Header = ({ forgotPassword }: HeaderProps) => {
  return (
    <Flex
      align="center"
      justify="center"
      gap={16}
      css={styles.Header}
      className={classNames({ forgotPassword })}>
      <Logo css={styles.Logo} />
    </Flex>
  );
};

type FooterProps = {
  isSignUp?: boolean;
};

export const Footer = ({ isSignUp }: FooterProps) => {
  const { t } = useTranslation();
  const { toSignUp, toSignIn } = useAuthenticator();

  const onClickSignCta = useCallback(() => {
    if (isSignUp) {
      toSignIn();
    } else {
      toSignUp();
    }
  }, [isSignUp, toSignIn, toSignUp]);

  return (
    <Flex align="center" justify="center" css={styles.Footer}>
      <Typography.Text>
        {t(isSignUp ? 'form.signInCta' : 'form.signUpCta')}{' '}
        <Typography.Link onClick={onClickSignCta}>
          {t(isSignUp ? 'form.signInCtaLink' : 'form.signUpCtaLink')}
        </Typography.Link>
      </Typography.Text>
    </Flex>
  );
};
