import { Global } from '@emotion/react';
import { ConfigProvider, theme } from 'antd';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { AuthProvider } from '~/providers/Auth';
import { MessageProvider } from '~/providers/Message';
import { MetadataProvider } from '~/providers/Metadata';
import { NavbarProvider } from '~/providers/Navbar';
import { PlanProvider } from '~/providers/PlanProvider';
import { ProjectProvider } from '~/providers/Project';
import { ThemeProvider } from '~/providers/Theme';
import { UserSettingsProvider } from '~/providers/UserSettings';

import { FullScreenMessage } from '~/components/FullScreenMessage';

import { Routes } from '~/routes';
import { GlobalStyles } from '~/styles/global';
import { getLocale } from '~/utils/get-locale';

export const App = () => {
  const { t, i18n } = useTranslation();

  // TODO: Implement feature flags system
  if (import.meta.env.VITE_MAINTENANCE_MODE === 'true') {
    return (
      <ConfigProvider
        locale={getLocale(i18n.language)}
        theme={{
          algorithm: theme.darkAlgorithm,
        }}>
        <ThemeProvider>
          <FullScreenMessage message={t('errors.maintenanceMode')} />
        </ThemeProvider>
      </ConfigProvider>
    );
  }

  return (
    <ConfigProvider
      locale={getLocale(i18n.language)}
      theme={{
        algorithm: theme.darkAlgorithm,
      }}>
      <ThemeProvider>
        <Global styles={GlobalStyles} />
        <MetadataProvider>
          {isMobile ? (
            <FullScreenMessage message={t('errors.mobileNotSupported')} />
          ) : (
            <MessageProvider>
              <AuthProvider>
                <UserSettingsProvider>
                  <PlanProvider>
                    <NavbarProvider>
                      <ProjectProvider>
                        <Routes />
                      </ProjectProvider>
                    </NavbarProvider>
                  </PlanProvider>
                </UserSettingsProvider>
              </AuthProvider>
            </MessageProvider>
          )}
        </MetadataProvider>
      </ThemeProvider>
    </ConfigProvider>
  );
};
