import { Button, Dropdown, Flex } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons';
import { AiOutlineFileUnknown } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa6';

import { capitalizeWords } from '~/utils/capitalize-words';

import * as styles from './styles';

type OptionsType = {
  label: string;
  value: string;
  icon: IconType;
};

type DropdownSelectProps<T extends OptionsType> = {
  options: T[];
  selectedOption?: T['value'];
  placeholder?: string;
  disabled?: boolean;
  buttonWidth?: string | number;
  onSelect: (value: T['value']) => void;
};

export const DropdownSelect = <T extends OptionsType>({
  disabled,
  options,
  selectedOption,
  placeholder,
  buttonWidth,
  onSelect,
}: DropdownSelectProps<T>) => {
  const { t } = useTranslation();

  const dropdownLabel = useMemo(() => {
    const selected = options.find(option => option.value === selectedOption);

    if (!selected)
      return (
        <Button icon={<AiOutlineFileUnknown />} style={{ width: buttonWidth }}>
          {selectedOption
            ? capitalizeWords(selectedOption)
            : (placeholder ?? '')}
          <FaChevronDown />
        </Button>
      );

    const Icon = selected.icon;

    return (
      <Button
        icon={
          <Flex>
            <Icon css={styles.Icon} />
          </Flex>
        }
        style={{ width: buttonWidth }}>
        {t(selected.label)}
        <FaChevronDown />
      </Button>
    );
  }, [buttonWidth, options, placeholder, selectedOption, t]);

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: options.map(option => ({
          type: 'item',
          key: option.value,
          label: option.label,
          icon: <option.icon css={styles.Icon} />,
          onClick: () => onSelect(option.value),
        })),
        selectable: true,
        selectedKeys: selectedOption ? [selectedOption] : undefined,
      }}
      disabled={disabled}>
      {dropdownLabel}
    </Dropdown>
  );
};
