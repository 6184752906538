import { css, Theme } from '@emotion/react';

export const Header = (theme: Theme) => css`
  margin: 2.5rem 2rem 0;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${theme.colorBorder};

  &.forgotPassword {
    margin-bottom: 1rem;
  }
`;

export const Logo = css`
  width: 100%;
  height: 5rem;
  object-fit: fill;
`;

export const Footer = css`
  width: 100%;
  margin-bottom: 1rem;
`;
