import { Flex, Modal, Space, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowRight } from 'react-icons/fa6';

import { DropdownSelect } from '~/components/DropdownSelect';

import { CURRENCIES, LANGUAGES } from '~/constants/settings';
import { getDefaultSettings } from '~/utils/get-default-settings';

import * as styles from './styles';

type SettingsModalProps = {
  show: boolean;
  onContinue: (data: { language: string; currency: string }) => void;
  isLoading: boolean;
};

export const SettingsModal = ({
  show,
  onContinue,
  isLoading,
}: SettingsModalProps) => {
  const { t, i18n } = useTranslation();

  const [settings, setSettings] = useState(getDefaultSettings(i18n.language));

  return (
    <Modal
      title={t('settings.welcome')}
      centered
      closable={false}
      maskClosable={false}
      cancelButtonProps={{
        style: styles.CancelButton,
      }}
      okText={t('settings.confirm')}
      okButtonProps={{
        icon: <FaArrowRight />,
        iconPosition: 'end',
      }}
      onOk={() => onContinue(settings)}
      confirmLoading={isLoading}
      open={show}>
      <Space direction="vertical" size="large">
        <Typography.Text type="secondary">
          {t('settings.updateSettings')}
        </Typography.Text>
        <Flex
          align="center"
          justify="center"
          gap={8}
          css={styles.ButtonsContainer}>
          <DropdownSelect
            options={CURRENCIES}
            onSelect={value =>
              setSettings(prevSettings => ({
                ...prevSettings,
                currency: value,
              }))
            }
            selectedOption={settings.currency}
            buttonWidth={110}
          />
          <DropdownSelect
            options={LANGUAGES}
            onSelect={value =>
              setSettings(prevSettings => ({
                ...prevSettings,
                language: value,
              }))
            }
            selectedOption={settings.language}
            buttonWidth={150}
          />
        </Flex>
      </Space>
    </Modal>
  );
};
